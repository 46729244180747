//import {confirmAlert} from 'react-confirm-alert';
//import 'react-confirm-alert/src/react-confirm-alert.css';
export const APP_VERSION = '1.0';

export const API_ROOT = 'http://54.176.169.179:3015';
export const API_URL = 'http://54.176.169.179:3015';
export const APP_DOMAIN = 'http://54.176.169.179:3015';
export const BASE_URL = 'http://54.176.169.179:3015';
export const BASE_URL_LIVE = 'http://54.176.169.179:3015';


export const WEBSITE_TITLE = "Online Demand Solution - Merchant";
export const REJECT_ORDER = 'Do you really want to reject this order.';
export const REJECT_ORDER_SUCCESS = 'Order has been rejected successfully!';
export const ACCEPT_ORDER = 'Do you really want to accept this order.';
export const ACCEPT_ORDER_SUCCESS = 'Order has been accepted successfully!';
export const MARK_READY = 'Do you set your order status ready.';
export const MARK_READY_SUCCESS = 'Order status has been set as ready successfully!';
export const DELETE_CONFIRMATION = "Do you really want to delete it!";
export const DELETE_SUCCESS = "Record has been deleted successfully";
export const ACTIVATE_SUCCESS = "Item has been activated successfully";
export const DEACTIVATE_SUCCESS = "Item has been deactivated successfully";
export const ACTIVATE_CONFIRMATION = "Do you want to activate this item ?";
export const ORDER_STOCKOUT_SUCCESS = "Order marked out of stock successfully.";
export const DEACTIVATE_CONFIRMATION = "Do you want to deactivate this item?";
export const MARK_DELIVERED = 'Do you set your order status delivered.';
export const MARK_DELIVERED_SUCCESS = 'Order status has been set as delivered successfully!';

export const PAGE_LIMIT = 20;
//settings panel 
export const PREPARATION_TIME = "Prepration time";
export const PREPARATION_TIME_TEXT = "Average time to prepare any order you can set time by clicking on it.";
export const ORDER_TIME = "Order Timings";
export const ORDER_TIME_TEXT = "Set your restaurant operational time for delivery";
export const SHOP_TAXES = "Taxes";
export const SHOP_TAXES_TEXT = "Set all types of taxes your restaurant have";

export const BASIC_SETTINGS = 'Other Settings';
export const BASIC_SETTINGS_TEXT = 'Notification and other settings';
export const START_PREPARE = 'Do you want to send this order to prepare';
export const START_PREPARE_SUCCESS = 'Order status has been set as preparing';

export const ADDONS_CATEGORY = [
  { value: 'SINGLE_WITH_REQUIRED', label: 'Single select required' },
  { value: 'SINGLE_WITH_OPTIONAL', label: 'Single select optional' },
  { value: 'MULTIPLE_WITH_REQUIRED', label: 'Multiple select required' },
  { value: 'MULTIPLE_WITH_OPTIONAL', label: 'Multiple select optional' }
];

export const VEG_CATEGORY = [
  { value: '0', label: 'Non-veg' },
  { value: '1', label: 'Veg' }
];

export const PAYMENT_TYPE = [
  { value: '0', label: 'Cash' },
  { value: '1', label: 'Credit Card' },
  { value: '2', label: 'Wallet' },
  { value: '3', label: 'Debit Card' },
  { value: '4', label: 'Token' }
];

export function disableInvalidOptions(selection){
  let options = CATEGORY_OPENING_CLOSING_DAY;
  if(selection.length <= 1){
    return options;
  }
  selection.map((value) => {
    switch(value.category_label){
      case '1':
      case '2':
      case '3':
      case '4':
      case '7':
        options = options.map((option) => {
          if(option.value == '10' || option.value == '8' || option.value == value.category_label){
            return {...option, isDisabled: true};
          }

          return option;
        });
        break;

      case '6':
      case '5':
        options = options.map((option) => {
          if(option.value == '10' || option.value == '9' || option.value == value.category_label){
            return {...option, isDisabled: true};
          }

          return option;
        });
        break;

      case '10':
        options = options.map((option) => {
          return {...option, isDisabled: true};
        });
        break;

      case '9':
        options = options.map((option) => {
          if(option.value == '10' || option.value == '6' || option.value == '5' || option.value == value.category_label){
            return {...option, isDisabled: true};
          }

          return option;
        });
        break;

      case '8':
        options = options.map((option) => {
          if(option.value != '9' && option.value != '6' && option.value != '5'){
            return {...option, isDisabled: true};
          }

          return option;
        });
        break;
    }
  });
  //console.log('options', selection);
  return options;
}

export function disableDaysInvalidOptions(selection){
  let options = CATEGORY_OPENING_CLOSING_DAY;
  if(selection.length <= 1){
    return options;
  }
  selection.map((value) => {
    switch(value.category_label){
      case '1':
      case '2':
      case '3':
      case '4':
      case '7':
        options = options.map((option) => {
          if(option.value == '10' || option.value == '8'){
            return {...option, isDisabled: true};
          }

          return option;
        });
        break;

      case '6':
      case '5':
        options = options.map((option) => {
          if(option.value == '10' || option.value == '9'){
            return {...option, isDisabled: true};
          }

          return option;
        });
        break;

      case '10':
        options = options.map((option) => {
          if(option.value == '9' || option.value == '8' || option.value == '7' || option.value == '6' || option.value == '7' || option.value == '5' || option.value == '4' || option.value == '3' || option.value == '2' || option.value == '1'){
            return {...option, isDisabled: true};
          }
          return option;
        });
        break;

      case '9':
        options = options.map((option) => {
          if(option.value == '10' || option.value == '6' || option.value == '5'){
            return {...option, isDisabled: true};
          }

          return option;
        });
        break;

      case '8':
        options = options.map((option) => {
          if(option.value != '9' && option.value != '6' && option.value != '5' && option.value != '8'){
            return {...option, isDisabled: true};
          }

          return option;
        });
        break;
    }
  });
  //console.log('options', selection);
  return options;
}

export const day_options = ['', 'Monday', 'Tuesday','Wednesday','Thursday','Friday','Saturday','Sunday', 'Sunday-Thursday', 'Friday-Saturday', 'Everyday'];


export const time_options = {'0':'12 am', '30':'00:30 am', '60':'01:00 am', '90':'01:30 am', '120':'02:00 am', '150':'02:30 am', '180':'03:00 am', '210':'03:30 am', '240':'04:00 am', '270':'04:30 am', '300':'05:00 am', '330':'05:30 am', '360':'06:00 am', '390':'06:30 am', '420':'07:00 am', '450':'07:30 am', '480':'08:00 am', '510':'08:30 am', '540':'09:00 am', '570':'09:30 am', '600':'10:00 am', '630':'10:30 am', '660':'11:00 am', '690':'11:30 am', '720':'12:00 pm', '750':'12:30 pm', '780':'01:00 pm', '810':'01:30 pm', '840':'02:00 pm', '870':'02:30 pm', '900':'03:00 pm', '930':'03:30 pm', '960':'04:00 pm', '990':'04:30 pm', '1020':'05:00 pm', '1050':'05:30 pm', '1080':'06:00 pm', '1110':'06:30 pm', '1140':'07:00 pm', '1170':'07:30 pm', '1200':'08:00 pm', '1230':'08:30 pm', '1260':'09:00 pm', '1290':'09:30 pm', '1320':'10:00 pm', '1350':'10:30 pm', '1380':'11:00 pm', '1410':'11:30 pm', '1439':'11:59 pm'};


export const CATEGORY_OPENING_CLOSING_DAY = [
  { value: '10', label: 'Everyday' },
  { value: '8', label: 'Sunday-Thursday' },
  { value: '9', label: 'Friday-Saturday' },
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
  { value: '7', label: 'Sunday' }
];


export const CATEGORY_OPENING_TIME = [
  { value: '0', label: '00:00' },
  { value: '30', label: '00:30' },
  { value: '60', label: '01:00' },
  { value: '90', label: '01:30' },
  { value: '120', label: '02:00' },
  { value: '150', label: '02:30' },
  { value: '180', label: '03:00' },
  { value: '210', label: '03:30' },
  { value: '240', label: '04:00' },
  { value: '270', label: '04:30' },
  { value: '300', label: '05:00' },
  { value: '330', label: '05:30' },
  { value: '360', label: '06:00' },
  { value: '390', label: '06:30' },
  { value: '420', label: '07:00' },
  { value: '450', label: '07:30' },
  { value: '480', label: '08:00' },
  { value: '510', label: '08:30' },
  { value: '540', label: '09:00' },
  { value: '570', label: '09:30' },
  { value: '600', label: '10:00' },
  { value: '630', label: '10:30' },
  { value: '660', label: '11:00' },
  { value: '690', label: '11:30' },
  { value: '720', label: '12:00' },
  { value: '750', label: '12:30' },
  { value: '780', label: '13:00' },
  { value: '810', label: '13:30' },
  { value: '840', label: '14:00' },
  { value: '870', label: '14:30' },
  { value: '900', label: '15:00' },
  { value: '930', label: '15:30' },
  { value: '960', label: '16:00' },
  { value: '990', label: '16:30' },
  { value: '1020', label: '17:00' },
  { value: '1050', label: '17:30' },
  { value: '1080', label: '18:00' },
  { value: '1110', label: '18:30' },
  { value: '1140', label: '19:00' },
  { value: '1170', label: '19:30' },
  { value: '1200', label: '20:00' },
  { value: '1230', label: '20:30' },
  { value: '1260', label: '21:00' },
  { value: '1290', label: '21:30' },
  { value: '1320', label: '22:00' },
  { value: '1350', label: '22:30' },
  { value: '1380', label: '23:00' },
  { value: '1410', label: '23:30' },
  { value: '1439', label: '23:59' }
];

export const CATEGORY_OPENING_CLOSING_TIME = [
  { value: '30', label: '00:29' },
  { value: '60', label: '00:59' },
  { value: '90', label: '01:29' },
  { value: '120', label: '01:59' },
  { value: '150', label: '02:29' },
  { value: '180', label: '02:59' },
  { value: '210', label: '03:29' },
  { value: '240', label: '03:59' },
  { value: '270', label: '04:29' },
  { value: '300', label: '04:59' },
  { value: '330', label: '05:29' },
  { value: '360', label: '05:59' },
  { value: '390', label: '06:29' },
  { value: '420', label: '06:59' },
  { value: '450', label: '07:29' },
  { value: '480', label: '07:59' },
  { value: '510', label: '08:29' },
  { value: '540', label: '08:59' },
  { value: '570', label: '09:29' },
  { value: '600', label: '09:59' },
  { value: '630', label: '10:29' },
  { value: '660', label: '10:59' },
  { value: '690', label: '11:29' },
  { value: '720', label: '11:59' },
  { value: '750', label: '12:29' },
  { value: '780', label: '12:59' },
  { value: '810', label: '13:29' },
  { value: '840', label: '13:59' },
  { value: '870', label: '14:29' },
  { value: '900', label: '14:59' },
  { value: '930', label: '15:29' },
  { value: '960', label: '15:59' },
  { value: '990', label: '16:29' },
  { value: '1020', label: '16:59' },
  { value: '1050', label: '17:29' },
  { value: '1080', label: '17:59' },
  { value: '1110', label: '18:29' },
  { value: '1140', label: '18:59' },
  { value: '1170', label: '19:29' },
  { value: '1200', label: '19:59' },
  { value: '1230', label: '20:29' },
  { value: '1260', label: '20:59' },
  { value: '1290', label: '21:29' },
  { value: '1320', label: '21:59' },
  { value: '1350', label: '22:29' },
  { value: '1380', label: '22:59' },
  { value: '1410', label: '23:29' },
  { value: '1439', label: '23:59' }
];


// export const CATEGORY_OPENING_TIME = [
//   { value: '0', label: '00:00' },
//   { value: '31', label: '00:31' },
//   { value: '61', label: '01:01' },
//   { value: '91', label: '01:31' },
//   { value: '121', label: '02:01' },
//   { value: '151', label: '02:31' },
//   { value: '181', label: '03:01' },
//   { value: '211', label: '03:31' },
//   { value: '241', label: '04:01' },
//   { value: '271', label: '04:31' },
//   { value: '301', label: '05:01' },
//   { value: '331', label: '05:31' },
//   { value: '361', label: '06:01' },
//   { value: '391', label: '06:31' },
//   { value: '421', label: '07:01' },
//   { value: '451', label: '07:31' },
//   { value: '481', label: '08:01' },
//   { value: '511', label: '08:31' },
//   { value: '541', label: '09:01' },
//   { value: '571', label: '09:31' },
//   { value: '601', label: '10:01' },
//   { value: '631', label: '10:31' },
//   { value: '661', label: '11:01' },
//   { value: '691', label: '11:31' },
//   { value: '721', label: '12:01' },
//   { value: '751', label: '12:31' },
//   { value: '781', label: '13:01' },
//   { value: '811', label: '13:31' },
//   { value: '841', label: '14:01' },
//   { value: '871', label: '14:31' },
//   { value: '901', label: '15:01' },
//   { value: '931', label: '15:31' },
//   { value: '961', label: '16:01' },
//   { value: '991', label: '16:31' },
//   { value: '1021', label: '17:01' },
//   { value: '1051', label: '17:31' },
//   { value: '1081', label: '18:01' },
//   { value: '1111', label: '18:31' },
//   { value: '1141', label: '19:01' },
//   { value: '1171', label: '19:31' },
//   { value: '1201', label: '20:01' },
//   { value: '1231', label: '20:31' },
//   { value: '1261', label: '21:01' },
//   { value: '1291', label: '21:31' },
//   { value: '1321', label: '22:01' },
//   { value: '1351', label: '22:31' },
//   { value: '1381', label: '23:01' },
//   { value: '1411', label: '23:31' }
// ];

// export const CATEGORY_OPENING_CLOSING_TIME = [
//   { value: '0', label: '00:00' },
//   { value: '30', label: '00:30' },
//   { value: '60', label: '01:00' },
//   { value: '90', label: '01:30' },
//   { value: '120', label: '02:00' },
//   { value: '150', label: '02:30' },
//   { value: '180', label: '03:00' },
//   { value: '210', label: '03:30' },
//   { value: '240', label: '04:00' },
//   { value: '270', label: '04:30' },
//   { value: '300', label: '05:00' },
//   { value: '330', label: '05:30' },
//   { value: '360', label: '06:00' },
//   { value: '390', label: '06:30' },
//   { value: '420', label: '07:00' },
//   { value: '450', label: '07:30' },
//   { value: '480', label: '08:00' },
//   { value: '510', label: '08:30' },
//   { value: '540', label: '09:00' },
//   { value: '570', label: '09:30' },
//   { value: '600', label: '10:00' },
//   { value: '630', label: '10:30' },
//   { value: '660', label: '11:00' },
//   { value: '690', label: '11:30' },
//   { value: '720', label: '12:00' },
//   { value: '750', label: '12:30' },
//   { value: '780', label: '13:00' },
//   { value: '810', label: '13:30' },
//   { value: '840', label: '14:00' },
//   { value: '870', label: '14:30' },
//   { value: '900', label: '15:00' },
//   { value: '930', label: '15:30' },
//   { value: '960', label: '16:00' },
//   { value: '990', label: '16:30' },
//   { value: '1020', label: '17:00' },
//   { value: '1050', label: '17:30' },
//   { value: '1080', label: '18:00' },
//   { value: '1110', label: '18:30' },
//   { value: '1140', label: '19:00' },
//   { value: '1170', label: '19:30' },
//   { value: '1200', label: '20:00' },
//   { value: '1230', label: '20:30' },
//   { value: '1260', label: '21:00' },
//   { value: '1290', label: '21:30' },
//   { value: '1320', label: '22:00' },
//   { value: '1350', label: '22:30' },
//   { value: '1380', label: '23:00' },
//   { value: '1410', label: '23:30' },
//   { value: '1439', label: '23:59' }
// ];

export function clearSession(){
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('persist:root');
  window.location = '/';
}


export function verifyAndUpdateAppVersion(){
  // Display App Version
  const version = localStorage.getItem("APP_VERSION");
  if(version === null || version != APP_VERSION){
    localStorage.setItem("APP_VERSION", APP_VERSION);
    clearSession();
  }
}



export function validateEmail(email) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
    return (true)
  }
  return (false)
}

export function stripHtmlTags(str, length) {
  if ((str === null) || (str === '')) {
    return false;
  } else {
    str = str.toString();
    str = str.replace(/<[^>]*>/g, '')
    if (length && length > 0 && str.length > length) {
      str = str.substr(0, length);
      str = str + "...";
    }
  }
  return str;
}

export function FromatValidationErrors(errors, error_keys) {
  Object.keys(errors).map(function(key) {
    error_keys[key] = errors[key][0];
    return key;
  });
  return error_keys;
}

export function jsUcfirst(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function _dispatch(nextState, rerender = false, compName = null) {
  rerender = rerender
    ? new Date().getTime()
    : nextState.status;
  return {
    ...nextState,
    status: rerender,
    compName
  }
}

export function currencyFormat(amount, currency, showCurrencySymbol = true, floatValue = true){

  amount = floatValue ? parseFloat(amount).toFixed(2) : parseInt(amount);

  if(typeof JSON.parse(localStorage.getItem('auth')).user.currencySymbol !== 'undefined' && JSON.parse(localStorage.getItem('auth')).user.currencySymbol !== null){
    currency = JSON.parse(localStorage.getItem('auth')).user.currencySymbol;
  } else {
    currency = '₹';
  }
  
  return `${currency}${amount}`;
}