import { lazy } from 'react';
import moment from 'moment';

export const APP_VERSION = '1.0';

export function load(component){

  return lazy(() => import(`./components/${component}`));
}

export function fromNow(dateTime){
  return moment.utc(dateTime).fromNow();
}

export function utcToLocal(dateTime, format = 'DD-MM-YYYY'){
  return moment.utc(dateTime).local().format(format);
}

export function clearSession(){
  localStorage.removeItem('jwtToken');
  localStorage.removeItem('currencyCode');
  localStorage.removeItem('currencySymbol');
  localStorage.removeItem('auth');
  localStorage.removeItem('contentlanguage');
  localStorage.removeItem('dieterystatus');
  // redirect on root
  window.location = '/';
}

export function verifyAndUpdateAppVersion(){
  // Display App Version
  console.log('APP VERSION', APP_VERSION);

  const version = localStorage.getItem("APP_VERSION");
  if(version === null || version != APP_VERSION){
    localStorage.setItem("APP_VERSION", APP_VERSION);
    clearSession();
  }
}

export function _dispatch(nextState, rerender = false, compName = null) {
  rerender = rerender
    ? new Date().getTime()
    : nextState.status;
  return {
    ...nextState,
    status: rerender,
    compName
  }
}

export function authentication(){
  // With auth token
  if(typeof localStorage.getItem('jwtToken') !== 'undefined' && localStorage.getItem('jwtToken') !== null){
    return true;
  }

  return false;
}

export function getUser(){
  // With auth token
  if(typeof localStorage.getItem('auth') !== 'undefined' && localStorage.getItem('auth') !== null){
    return JSON.parse(localStorage.getItem('auth'));
  }

  return null;
}

export function getCurrencySymbol(){
  // With auth token
  if(typeof localStorage.getItem('currencySymbol') !== 'undefined' && localStorage.getItem('currencySymbol') !== null){
    return localStorage.getItem('currencySymbol');
  }

  return null;
}

export function getDieteryStatus(){
  // With auth token
  if(typeof localStorage.getItem('dieterystatus') !== 'undefined' && localStorage.getItem('dieterystatus') !== null){
    return localStorage.getItem('dieterystatus');
  }

  return 1;
}