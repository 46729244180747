import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};

export const RECOMMENDED_ITEM_SUCCESS = 'RECOMMENDED_ITEM_SUCCESS';
export const MENU_LIST_SUCCESS = 'MENU_LIST_SUCCESS';
export const MENU_CATEGORY_SUCCESS = 'MENU_CATEGORY_SUCCESS';
export const ADD_MENU_ITEM_SUCCESS = 'ADD_MENU_ITEM_SUCCESS';
export const ADD_MENU_CATEGORY_SUCCESS = 'ADD_MENU_CATEGORY_SUCCESS';
export const CATEGORY_DETAIL = 'CATEGORY_DETAIL';
export const CATEGORY_DETAIL_SUCCESS = 'CATEGORY_DETAIL_SUCCESS';
export const MENU_ITEM_DETAIL_SUCCESS = 'MENU_ITEM_DETAIL_SUCCESS';
export const UPDATE_MENU_CATEGORY_ORDER_SUCCESS = 'UPDATE_MENU_CATEGORY_ORDER_SUCCESS';
export const DELETE_MENU_ITEM_SUCCESS = 'DELETE_MENU_ITEM_SUCCESS';

const ROOT_URL = API_ROOT;
var token = "";

export function getRecommendedItems(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getitemrecommended`,
    
  });
}

export function getRecommendedItemsSuccess(payload){
  return {
      type: RECOMMENDED_ITEM_SUCCESS,
      payload: payload
    }
}

export function menuListing(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getmenulist`,
    
  });
}

export function menuListingSuccess(payload){
  return {
      type: MENU_LIST_SUCCESS,
      payload: payload
    }
}

export function menuCategory(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getshopcategories`,
    
  });
}

export function menuCategorySuccess(payload){
  return {
      type: MENU_CATEGORY_SUCCESS,
      payload: payload
    }
}

export function addMenuItems(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/merchant/addItems`
  });
}

export function addMenuItemsSuccess(payload) {
  return {
    type: ADD_MENU_ITEM_SUCCESS,
    payload: payload
  };
}

export function deleteMenuItem(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/merchant/deletemenuitem`
  });
}

export function deleteMenuItemSuccess(payload){
  return {
      type: DELETE_MENU_ITEM_SUCCESS,
      payload: payload
    }
}

export function addMenuCategories(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/merchant/addcategory`
  });
}

export function addMenuCategoriesSuccess(payload) {
  return {
    type: ADD_MENU_CATEGORY_SUCCESS,
    payload: payload
  };
}

export function deleteMenuCategory(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/merchant/deletecategory`
  });
}

export function categoryDetailById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getcategorybyid`,
  });
}

export function categoryDetailByIdSuccess(payload) {
  return {
    type: CATEGORY_DETAIL_SUCCESS,
    payload: payload
  };
}

export function menuItemDetailById(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getitemsformid`,
  });
}

export function menuItemDetailByIdSuccess(payload) {
  return {
    type: MENU_ITEM_DETAIL_SUCCESS,
    payload: payload
  };
}

export function statusItemAvailability(data){
  return axios({
    method: 'PUT',
    data: data,
    url: `${ROOT_URL}/merchant/markitemavailability`,
  });
}

export function updateMenuCategoryOrder(data) {
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/merchant/changecategoryposition`
  });
}

export function changeItemPosition(data){
  return axios({
    method: 'POST',
    data: data,
    url: `${ROOT_URL}/merchant/changeitemposition`
  });
}

export function getItemAttributes(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getitemattributes`,
  });
}

export function markVariantAvailability(data){
  return axios({
    method: 'PUT',
    data: data,
    url: `${ROOT_URL}/merchant/markvariantavailability`,
  });
}
export function markAddonAvailability(data){
  return axios({
    method: 'PUT',
    data: data,
    url: `${ROOT_URL}/merchant/markaddonavailability`,
  });
}
