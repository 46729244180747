import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import User from './users';
import Order from './orders';
import Menu from './menus';
import Notification from './notification';

const appReducer = combineReducers({
  form: formReducer,
  User,
  Order,
  Menu,
  Notification
});

const rootReducer = (state, action) => {
  return appReducer(state, action)
}

export default rootReducer;