import {
  _dispatch,
	SIGNIN_USER,
  SIGNIN_USER_PENDING,
  SIGNIN_USER_SUCCESS,
  SIGNIN_USER_FAILURE,
  LOGOUT_USER,
  USER_DETAIL_SUCCESS
} from '../actions/users';

import {FromatValidationErrors} from "../constants";

const INITIAL_STATE = {user: null, status:null, error:null, loading: false, auth: false, user_info: {} };

export default function(state = INITIAL_STATE, action) {
  let error;
  let keys;
  switch(action.type) {
    case SIGNIN_USER_PENDING:// sign in user,  set loading = true and status = signin
      return { ...state, user: null, status:'signin', error:null, loading: true};

    case SIGNIN_USER_SUCCESS:
      console.log('action', action);
      return { ...state, user: action.data, auth: true, error:null, loading: false}; //<-- authenticated

    case SIGNIN_USER_FAILURE:// return error and make loading = false
      keys = {email: '', password: '', sub_domain: ''};
      error = FromatValidationErrors(action.data, keys);
      return { ...state, user: null, status:'signin', error: error, loading: false};
      break;
    case USER_DETAIL_SUCCESS:
      return _dispatch({ ...state, user_info: action.payload}, true, 'userinfo');
      break;

    case LOGOUT_USER:

      return INITIAL_STATE;

    
    default:
      return state;
  }
}
