import axios from 'axios';

import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};
//Sign In User
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_PENDING = 'SIGNIN_USER_PENDING';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNIN_USER_FAILURE = 'SIGNIN_USER_FAILURE';
export const LOGOUT_USER = 'LOGOUT_USER';
export const USER_DETAIL = 'USER_DETAIL';
export const ADD_SHOP_ORDER_TIMING_SUCCESS = 'ADD_SHOP_ORDER_TIMING_SUCCESS';
export const SHOP_TIMING_SUCCESS ='SHOP_TIMING_SUCCESS';
export const USER_DETAIL_SUCCESS = 'USER_DETAIL_SUCCESS';


const ROOT_URL = API_ROOT;

let token = null;

// export function resetToken() {//used for logout
//   return {
//     type: RESET_TOKEN
//   };
// }

export function signInUser(formValues) {
  return axios.post(`${ROOT_URL}/merchant/login`, formValues);
  
  // return axios({
  //   method: 'POST',
  //   data: formValues,
  //   url: `${ROOT_URL}/user/login`,
  // });
}

export function signInUserSuccess(user) {
  return {
    type: SIGNIN_USER_SUCCESS,
    data: user
  };
}

export function signInUserFailure(error) {
  return {
    type: SIGNIN_USER_FAILURE,
    data: error
  };
}

export function logoutUser() {

  return {
    type: LOGOUT_USER
  };
}

export function statusShopAvailability(data){
  return axios({
    method: 'PUT',
    data: data,
    url: `${ROOT_URL}/merchant/markshopavailability`,
  });
}

export function userDetail(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getshopavailability`,
    
  });
}

export function userDetailSuccess(payload) {
  return {
    type: USER_DETAIL_SUCCESS,
    payload: payload
  };
}

export function savePreparationTime(data){
  return axios({
    method: 'PUT',
    data: data,
    url: `${ROOT_URL}/merchant/updateshop`,
  });
}

export function addShopOrderTiming(props){
  return axios({
    method: 'POST',
    data: props,
    url: `${ROOT_URL}/merchant/addshoptiming`
  });
}

export function addShopOrderTimingSuccess(payload) {
  return {
    type: ADD_SHOP_ORDER_TIMING_SUCCESS,
    payload: payload
  };
}

export function getShopTiming(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getshoptiming`,
  });
}

export function getShopTimingSuccess(payload) {
  return {
    type: SHOP_TIMING_SUCCESS,
    payload: payload
  };
}

export function helpRequestToClient(props){
  return axios({
    method : 'POST',
    data : props,
    url: `${ROOT_URL}/merchant/requestprovidercallback`
  })
}

export function forgotPasswordRequest(props){
  return axios({
    method : 'POST',
    data : props,
    url: `${ROOT_URL}/merchant/forgotpassword`
  })
}

export function resetForgotPasswordRequest(props){
  return axios({
    method : 'POST',
    data : props,
    url: `${ROOT_URL}/merchant/resetforgotpassword`
  })
}

export function getProfileInfo(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getmerchantprofile`,
  });
}

export function getAllLanguages(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/common/getalllanguages`,
  });
}

export function logout(props){
  return axios({
    method : 'POST',
    data : props,
    url: `${ROOT_URL}/merchant/logout`
  })
}