import axios from 'axios';
import { API_ROOT, _dispatch } from '../constants';

export {_dispatch};
//fetch client list

export const ORDER_LIST = 'ORDER_LIST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const CHANGE_ORDER_STATUS = 'CHANGE_ORDER_STATUS';
export const CONFIRM_ORDER_LIST = 'CONFIRM_ORDER_LIST';
export const CONFIRM_ORDER_LIST_SUCCESS = 'CONFIRM_ORDER_LIST_SUCCESS';
export const NEW_ORDER_LIST_SUCCESS = 'NEW_ORDER_LIST_SUCCESS';
export const NEW_ORDER_STREAM_SUCCESS = 'NEW_ORDER_STREAM_SUCCESS';
export const UPDATE_NEW_ORDERS_LIST = 'UPDATE_NEW_ORDERS_LIST';
export const REJECT_ORDER_BY_MERCHANT = 'REJECT_ORDER_BY_MERCHANT';
export const ACCEPT_ORDER_BY_MERCHANT = 'ACCEPT_ORDER_BY_MERCHANT';
export const UPDATE_CONFIRM_ORDER_LIST = 'UPDATE_CONFIRM_ORDER_LIST';

export const OUT_STOCK_ORDER_LIST_SUCCESS = 'OUT_STOCK_ORDER_LIST_SUCCESS';
export const MARK_OUTSTOCK_ORDER_BY_MERCHANT = 'MARK_OUTSTOCK_ORDER_BY_MERCHANT';
export const UPDATE_OUTSTOCK_ORDER_LIST = 'UPDATE_OUTSTOCK_ORDER_LIST';
export const MARK_READY_ORDER_LIST_SUCCESS = 'MARK_READY_ORDER_LIST_SUCCESS';
export const ON_WAY_ORDER_LIST_SUCCESS = 'ON_WAY_ORDER_LIST_SUCCESS';

export const ASSIGNED_DRIVER_ORDER_SUCCESS = 'ASSIGNED_DRIVER_ORDER_SUCCESS';
export const DRIVER_PICKED_UP_ORDER = 'DRIVER_PICKED_UP_ORDER';
export const DRIVER_DELIVERED_ORDER = 'DRIVER_DELIVERED_ORDER';

export const SCHEDULED_ORDER_LIST_SUCCESS = 'SCHEDULED_ORDER_LIST_SUCCESS';
export const UPDATE_SCHEDULED_ORDER_LIST = 'UPDATE_SCHEDULED_ORDER_LIST';
export const ACCEPT_SCHEDULE_ORDER_BY_MERCHANT = 'ACCEPT_SCHEDULE_ORDER_BY_MERCHANT';

export const SET_CONFIRM_ORDER_LIST = 'SET_CONFIRM_ORDER_LIST';
export const UPDATE_READY_ORDER_LIST = 'UPDATE_READY_ORDER_LIST';
export const ORDER_COUNT_SUCCESS = 'ORDER_COUNT_SUCCESS';

export const REMOVE_OUTOFSTOCK_STREAM_SUCCESS = 'REMOVE_OUTOFSTOCK_STREAM_SUCCESS';
export const SET_READY_ORDER_LIST = 'SET_READY_ORDER_LIST';
export const MANAGE_ORDER_STATUS_SOCKET = 'MANAGE_ORDER_STATUS_SOCKET';

const ROOT_URL = API_ROOT;
var token = "";

export function orderList(params) {
  params.orderStatus = JSON.stringify(params.orderStatus);
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getorderbystatus`
  });
}

export function changeOrderStatus(data, index, successToast = null) {
  // return axios({
  //   method: 'POST',
  //   data: data,
  //   url: `${ROOT_URL}/merchant/changeorderstatus`
  // });

  return (dispatch) => {
    return axios(`${ROOT_URL}/merchant/changeorderstatus`, {
      method: 'POST',
      data : data
    })
    .then(response => {
      if(successToast !== null)
        successToast();
      dispatch({
        type: SET_CONFIRM_ORDER_LIST,
        payload: index
      });
      dispatch({
        type: UPDATE_READY_ORDER_LIST,
        payload: response.data.data.order.statusCount
      });
      
    })
    .catch((error) => {
      console.log(error);
    })
  }
}

export function rejectOrderByMerchant(data, index){
  return (dispatch) => {
    return axios(`${ROOT_URL}/merchant/changeorderstatus`, {
      method: 'POST',
      data : data
    })
    .then(response => {
      dispatch({
        type: REJECT_ORDER_BY_MERCHANT,
        payload: index
      });
    })
    .catch((error) => {
      console.log(error);
    })
  }
}

export function acceptOrderByMerchant(data, index, successToast = null){
  if(data.schedule == 1){
    var reqMethod = UPDATE_SCHEDULED_ORDER_LIST;
    var accMethod = ACCEPT_ORDER_BY_MERCHANT; //ACCEPT_SCHEDULE_ORDER_BY_MERCHANT;
  } else if(data.schedule == -1){
    var reqMethod = UPDATE_CONFIRM_ORDER_LIST;
    var accMethod = ACCEPT_SCHEDULE_ORDER_BY_MERCHANT;
  } else {
    var reqMethod = UPDATE_CONFIRM_ORDER_LIST;
    var accMethod = ACCEPT_ORDER_BY_MERCHANT;
  }
  return (dispatch) => {
    return axios(`${ROOT_URL}/merchant/changeorderstatus`, {
      method: 'POST',
      data : data
    })
    .then(response => {
      if(successToast !== null)
        successToast();
      dispatch({
        type: accMethod,
        payload: index
      });
      dispatch({
        type: reqMethod,
        payload: response.data.data.order
      });
      
    })
    .catch((error) => {
      console.log(error);
    })
  }
}


export function confirmOrderList(params) {
  params.orderStatus = JSON.stringify(params.orderStatus);
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getorderbystatus`,
    
  });
}

export function confirmOrderListSuccess(payload){
  return {
      type: CONFIRM_ORDER_LIST_SUCCESS,
      payload: payload
    }
}

export function newOrderStreamSuccess(payload){
  window.triggerAlert();
  return {
      type: NEW_ORDER_STREAM_SUCCESS,
      payload: payload
    }
}

export function outOfStockUpdate(payload){
  if(payload.order.order.status){
    return {
      type: REMOVE_OUTOFSTOCK_STREAM_SUCCESS,
      payload: payload
    }
  } else { 
    return (dispatch) => {
      dispatch({
        type: NEW_ORDER_STREAM_SUCCESS,
        payload: payload
      });
      dispatch({
        type: REMOVE_OUTOFSTOCK_STREAM_SUCCESS,
        payload: payload
      });
    }
  }
}


export function updateNewOrders(payload){
  return {
      type: UPDATE_NEW_ORDERS_LIST,
      payload: payload
    }
}

export function newOrderListSuccess(payload){
  return {
      type: NEW_ORDER_LIST_SUCCESS,
      payload: payload
    }
}

export function outStockOrderList(params) {
  params.orderStatus = JSON.stringify(params.orderStatus);
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getorderbystatus`,
    
  });
}

export function outStockOrderListSuccess(payload){
  return {
      type: OUT_STOCK_ORDER_LIST_SUCCESS,
      payload: payload
    }
}

export function markOutStockToServiceProvider(data, index, successToast = null){
  return (dispatch) => {
    return axios(`${ROOT_URL}/merchant/outofstocknotification`, {
      method: 'POST',
      data : data
    })
    .then(response => {

      if(successToast !== null)
        successToast();
      dispatch({
        type: MARK_OUTSTOCK_ORDER_BY_MERCHANT,
        payload: index,
        orderStatus: data.orderStatus ? data.orderStatus : 0
      });
      // dispatch({
      //   type: UPDATE_OUTSTOCK_ORDER_LIST,
      //   payload: response.data.data.order
      // });
      
    })
    .catch((error) => {
      console.log(error);
    })
  }
}

export function markReadtOrderList(params) {
  params.orderStatus = JSON.stringify(params.orderStatus);
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getorderbystatus`,
    
  });
}

export function markReadtOrderListSuccess(payload){
  return {
      type: MARK_READY_ORDER_LIST_SUCCESS,
      payload: payload
    }
}

export function onWayOrderList(params) {
  params.orderStatus = JSON.stringify(params.orderStatus);
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getorderbystatus`,
    
  });
}

export function onWayOrderListSuccess(payload){
  return {
      type: ON_WAY_ORDER_LIST_SUCCESS,
      payload: payload
    }
}

export function getOrderCount(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getstatuscount`
  });
}

export function getOrderCountSuccess(payload){
  return {
      type: ORDER_COUNT_SUCCESS,
      payload: payload
    }
}

export function assignedDriverToConfirmOrder(payload){
  return {
      type: ASSIGNED_DRIVER_ORDER_SUCCESS,
      payload: payload
    }
}

export function driverPickUpOrder(payload){
  return {
    type: DRIVER_PICKED_UP_ORDER,
    payload: payload
  }
}

export function driverDeliveredOrder(payload){
  return {
    type: DRIVER_DELIVERED_ORDER,
    payload: payload
  }
}

export function scheduledOrderList(params) {
  params.orderStatus = JSON.stringify(params.orderStatus);
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getorderbystatus`,
    
  });
}

export function scheduledOrderListSuccess(payload){
  return {
      type: SCHEDULED_ORDER_LIST_SUCCESS,
      payload: payload
    }
}

export function getdeliveringsetting(params) {
  return axios({
    method: 'GET',
    params: params,
    url: `${ROOT_URL}/merchant/getdeliveringsetting`
  });
}

export function setOrderDelivered(data, index, successToast = null) {
  return (dispatch) => {
    return axios(`${ROOT_URL}/merchant/changeorderstatus`, {
      method: 'POST',
      data : data
    })
    .then(response => {
      
      if(successToast !== null)
        successToast();
      dispatch({
        type: SET_READY_ORDER_LIST,
        payload: index
      });
      dispatch({
        type: UPDATE_READY_ORDER_LIST,
        payload: response.data.data.order.statusCount
      });
      
    })
    .catch((error) => {
      console.log(error);
    })
  }
}


export function updateSocketMerchantOrder(payload){
  return {
    type: MANAGE_ORDER_STATUS_SOCKET,
    payload: payload
  }
}

export function getorderbyid(params) {
  return axios({
    method: 'GET',
    params: params,
    headers: {contentlanguage: 'ar'},
    url: `${ROOT_URL}/merchant/getorderbyid`
  });
}
