import {load} from './utilities';
import React from 'react';
import { Redirect } from 'react-router-dom';

export const ROUTES = {
  ROOT: { 
  	path: '/',
  	exact: true,
  	render: () => <Redirect to="/login" />
  },
  FORGOTPASSWORD: { 
    path: '/forgotpassword',
    exact: true,
    component: load('ForgotPassword')
  },
  RESETFORGOTPASSWORD: { 
    path: '/resetforgotpassword/:index',
    exact: true,
    component: load('ResetForgotPassword')
  },
  DASHBOARD: { 
  	path: '/dashboard',
    component: load('Dashboard')
  },
  LOGOUT: { 
    path: '/logout',
    component: load('Logout')
  },
  NEWCONFIRMORDER: { 
    path: '/dashboard',
    exact: true,
    component: load('orders/NewConfirmOrder')
  },
  MENU: { 
    path: '/dashboard/menu',
    exact: true,
    component: load('menu/Menu')
  },
  PASTORDER: { 
    path: '/dashboard/pastorder',
    exact: true,
    component: load('orders/PastOrder')
  },
  TRANSITORDER: { 
    path: '/dashboard/transitorder',
    exact: true,
    component: load('orders/TransitOrder')
  },
  ADDMENUITEM: { 
    path: '/dashboard/addmenuitem',
    exact: true,
    component: load('menu/AddMenuItem')
  },
  EDITMENUITEM: { 
    path: '/dashboard/editmenuitem/:index',
    exact: true,
    component: load('menu/EditMenuItem')
  },
  CUSTOMIZEMENUITEMS: { 
    path: '/dashboard/customizemenuitems/:index',
    exact: true,
    component: load('menu/CustomizeMenuItems')
  },

  SETTING: { 
    path: '/dashboard/setting',
    exact: true,
    component: load('setting/Setting')
  },
  BUSINESS: { 
    path: '/dashboard/business',
    exact: true,
    component: load('business/Business')
  },
  LOGIN: { 
  	path: '/login',
  	exact: true,
    component: load('Login')
  },
  NOTFOUND: { 
    component: load('Sidebar')
  }
};