import {
  _dispatch,
  CONFIRM_ORDER_LIST_SUCCESS,
  NEW_ORDER_STREAM_SUCCESS,
  NEW_ORDER_LIST_SUCCESS,
  UPDATE_NEW_ORDERS_LIST,
  REJECT_ORDER_BY_MERCHANT,
  ACCEPT_ORDER_BY_MERCHANT,
  UPDATE_CONFIRM_ORDER_LIST,
  OUT_STOCK_ORDER_LIST_SUCCESS,
  MARK_OUTSTOCK_ORDER_BY_MERCHANT,
  UPDATE_OUTSTOCK_ORDER_LIST,
  MARK_READY_ORDER_LIST_SUCCESS,
  ON_WAY_ORDER_LIST_SUCCESS,
  ASSIGNED_DRIVER_ORDER_SUCCESS,
  DRIVER_PICKED_UP_ORDER,
  DRIVER_DELIVERED_ORDER,
  SCHEDULED_ORDER_LIST_SUCCESS,
  UPDATE_SCHEDULED_ORDER_LIST,
  ACCEPT_SCHEDULE_ORDER_BY_MERCHANT,
  SET_CONFIRM_ORDER_LIST,
  UPDATE_READY_ORDER_LIST,
  ORDER_COUNT_SUCCESS,
  REMOVE_OUTOFSTOCK_STREAM_SUCCESS,
  SET_READY_ORDER_LIST  
} from '../actions/orders';

const INITIAL_STATE = {status: null, new_orders: [], confirmed_orders: [], new_order_stream: [], out_stock_orders: [], mark_ready_orders: [], transit_orders:[], scheduled_orders: [], order_count:{} };

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case NEW_ORDER_LIST_SUCCESS:
      return (() => {
        const new_orders = [...action.payload];
        const new_order_stream = [...state.new_order_stream];
        
        new_order_stream.map(nItem => {
          new_orders.map((item, index) => {
            if(item._id == nItem._id){
              new_orders.splice(index, 1);
            }
          });
        });

        return _dispatch({ ...state, new_orders}, true, 'NewOrders');
      })();

      break;
    case CONFIRM_ORDER_LIST_SUCCESS:
      return _dispatch({ ...state, confirmed_orders: action.payload}, true, 'ConfirmOrders');
      break;
    case NEW_ORDER_STREAM_SUCCESS:
      return _dispatch({ ...state, new_order_stream: [action.payload.order, ...state.new_order_stream]}, true, 'NewOrderStream');
      break;
    case UPDATE_NEW_ORDERS_LIST:
      return _dispatch({ ...state, new_orders: [...action.payload, ...state.new_orders], new_order_stream: []}, true, 'NewOrderStreamReset');
      break;
    case OUT_STOCK_ORDER_LIST_SUCCESS:
      return _dispatch({ ...state, out_stock_orders: action.payload}, true, 'OutStockOrders');
      break;
    case REJECT_ORDER_BY_MERCHANT:
      return (() => {
        const new_orders = [...state.new_orders];
        new_orders.splice(action.payload, 1);
        return _dispatch({ ...state, new_orders}, true, 'NewOrders');
      })();
      break;
    case ACCEPT_ORDER_BY_MERCHANT:
      return (() => {
        const new_orders = [...state.new_orders];
        new_orders.splice(action.payload, 1);
        return _dispatch({ ...state, new_orders}, true, 'NewOrders');
      })();
    case MARK_OUTSTOCK_ORDER_BY_MERCHANT:
      return (() => {
        if(!action.orderStatus){
          const new_orders = [...state.new_orders];
          new_orders.splice(action.payload, 1);
          return _dispatch({ ...state, new_orders}, true, 'NewOrders');
        } else {
          const confirmed_orders = [...state.confirmed_orders];
          confirmed_orders.splice(action.payload, 1);
          return _dispatch({ ...state, confirmed_orders}, true, 'ConfirmOrders');
        }
      })();
    case UPDATE_CONFIRM_ORDER_LIST:
      return _dispatch({ ...state, confirmed_orders: [...state.confirmed_orders, action.payload]}, true, 'ConfirmOrders');
      break;
    case UPDATE_OUTSTOCK_ORDER_LIST:
      return _dispatch({ ...state, out_stock_orders: [...state.out_stock_orders, action.payload]}, true, 'OutStockOrders');
      break;
    case MARK_READY_ORDER_LIST_SUCCESS:
      return _dispatch({ ...state, mark_ready_orders: action.payload}, true, 'MarkReadyStockOrders');
    case ON_WAY_ORDER_LIST_SUCCESS:
      return _dispatch({ ...state, transit_orders: action.payload}, true, 'TransitOrders');
      break;
    case ASSIGNED_DRIVER_ORDER_SUCCESS :
      return (() => {
        let compName = null;
        const updated_order = action.payload.order;
        const confirmed_orders = [...state.confirmed_orders].map(item => {
          if(updated_order._id == item._id){
            compName = 'ConfirmOrders';
            return updated_order;
          }

          return item;
        });
        const mark_ready_orders = [...state.mark_ready_orders].map(item => {
          if(updated_order._id == item._id){
            compName = 'MarkReadyStockOrders';
            return updated_order;
          }

          return item;
        });
        return _dispatch({ ...state, confirmed_orders, mark_ready_orders}, true, compName);
      })(); 
      break;
    case DRIVER_PICKED_UP_ORDER :
      return (() => {
        const order_updates = [...action.payload.orders];
        const mark_ready_orders = [...state.mark_ready_orders];
        
        order_updates.map(nItem => {
          mark_ready_orders.map((item, index) => {
            if(item._id == nItem._id){
              mark_ready_orders.splice(index, 1);
            }
          });
        });
        return _dispatch({ ...state, mark_ready_orders}, true, 'MarkReadyStockOrders');

      })();
      break;
    case DRIVER_DELIVERED_ORDER : 
      return (() => {
        const order_deliver = action.payload;
        const transit_orders = [...state.transit_orders];

        transit_orders.map((item, index) => {
          if(item.orderId == order_deliver.orderId){
            transit_orders.splice(index, 1);
          }
        });
        return _dispatch({ ...state, transit_orders}, true, 'TransitOrders');

      })();
      break;
    case SCHEDULED_ORDER_LIST_SUCCESS:
      return _dispatch({ ...state, scheduled_orders: action.payload}, true, 'ScheduledOrders');
      break;
    case UPDATE_SCHEDULED_ORDER_LIST:
      return _dispatch({ ...state, scheduled_orders: [...state.scheduled_orders, action.payload]}, true, 'ScheduledOrders');
      break;
    case ACCEPT_SCHEDULE_ORDER_BY_MERCHANT:
      return (() => {
        const scheduled_orders = [...state.scheduled_orders];
        //console.log('ppppp', action.payload);
        scheduled_orders.splice(action.payload, 1);
        return _dispatch({ ...state, scheduled_orders}, true, 'ScheduledOrders');
      })();
    break;
    case SET_CONFIRM_ORDER_LIST:
      return (() => {
        const confirmed_orders = [...state.confirmed_orders];
        confirmed_orders.splice(action.payload, 1);
        return _dispatch({ ...state, confirmed_orders}, true, 'ConfirmOrders');
      })();
    break;

    case UPDATE_READY_ORDER_LIST:
      return _dispatch({ ...state, order_count: action.payload}, true, 'OrdeCount');

      break;
    case ORDER_COUNT_SUCCESS:
      return _dispatch({ ...state, order_count: action.payload}, true, 'OrdeCount');
      break;

    case REMOVE_OUTOFSTOCK_STREAM_SUCCESS :
      return (() => {
        const order_updates = {...action.payload.order.order};
        const out_stock_orders = [...state.out_stock_orders];

        out_stock_orders.map((item, index) => {
          if(item.orderId == order_updates.orderId){
            out_stock_orders.splice(index, 1);
          }
        });
        return _dispatch({ ...state, out_stock_orders}, true, 'OutStockOrders');

      })();
      break;
    case SET_READY_ORDER_LIST:
      return (() => {
        const mark_ready_orders = [...state.mark_ready_orders];
        mark_ready_orders.splice(action.payload, 1);
        return _dispatch({ ...state, mark_ready_orders}, true, 'MarkReadyStockOrders');
      })();
    break;

    default:
      return state;
  }
}