import {
  _dispatch,
  MENU_LIST_SUCCESS,
  MENU_CATEGORY_SUCCESS,
  DELETE_MENU_ITEM_SUCCESS
} from '../actions/menus';

const INITIAL_STATE = {status: null, menu_list: [], menu_category:[]};

export default function(state = INITIAL_STATE, action) {
  switch(action.type) {
    case MENU_LIST_SUCCESS:
      return _dispatch({ ...state, menu_list: action.payload}, true, 'menuFullList');
      break;
    case MENU_CATEGORY_SUCCESS:
      return _dispatch({ ...state, menu_category: action.payload}, true, 'menuCategory');
      break;
    case DELETE_MENU_ITEM_SUCCESS:
      return _dispatch({ ...state, menu_list: action.payload}, true, 'menuFullList');
      break;
    default:
      return state;
  }
}